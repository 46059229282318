<template>
  <div>
        <vx-card id="invoice-container">
          <!-- INVOICE CONTENT -->
          <div class="p-0 md:p-base">
            <b-table
              striped
              hover
              :stacked="windowWidth < 800"
              :fields="fields"
              :items="withDraw"
              responsive="sm"
              class="table table-dark text-sm md:text-base"
            >
              <!-- A virtual column -->
              <template #cell(id)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(amount)="data">
                <span
                  :class="
                    data.item.type === 'withdraw'
                      ? 'text-danger'
                      : 'text-success'
                  "
                  >{{ data.value }}</span
                >
              </template>
            </b-table>
            <vs-pagination
              :total="countPage"
              v-model="pageNumber"
            ></vs-pagination>
          </div>
        </vx-card>

  </div>
</template>

<script>
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
export default {
  name: 'TransactionPage',
  components: {
    datePicker: VuePersianDatetimePicker
  },
  data () {
    return {
      fields: [
        { key: 'id', label: 'شماره' },
        { key: 'created_at', label: 'تاریخ ' },
        { key: 'meta', label: 'توضیح' },
        { key: 'amount', label: 'مبلغ نهایی' }
      ],
      withDraw: [],
      monthlyReport: [],
      pageNumber: 1,
      descriptionPopup: false,
      description: [],
      countPage: 1,
      yearMonth : '',
      radios: 'deposit',
      monthlyFields: [
        { key: 'report_month', label: 'ماه' },
        { key: 'amount', label: 'مصرف کل (تومان)' },
        { key: 'op', label: 'مشاهده ریز مصرف' }
      ]
    }
  },
  watch: {
    pageNumber () {
      this.getTransaction()
    },
    radios () {
      this.getTransaction()
    },
    yearMonth (value) {
      const dateArray = value.split('/')
      const [year, day] = dateArray
      this.getMonthlyReport(year, day)
    }
  },
  methods: {
    setDescPopUp (meta) {
      this.descriptionPopup = true
      this.description = JSON.parse(meta)
      // console.log(this.description)
    },
    getTransaction () {
      this.$vs.loading()
      this.$http
        .get(`/billing/pay/transaction?type=${this.radios}&page=${this.pageNumber}`)
        .then((res) => {
          this.$vs.loading.close()
          this.withDraw = res.data.params.data.map(item => {
            return {
              ...item,
              meta: item.meta.replace(/InvoiceID/g, 'شناسه پرداخت ')
            }
          })
          this.countPage = res.data.params.last_page
        })
        .catch(() => {})
    },

    getMonthlyReport (year = '', day = '') {
      this.$vs.loading()
      this.$http
        .get(`/billing/monthly/report?year=${year}&month=${day}`)
        .then((result) => {
          this.monthlyReport = result.data.params.data
        })
        .catch(() => {})
    }
  },
  created () {
    this.getTransaction()
    this.getMonthlyReport()
  },
  computed: {
    windowWidth () {
      return this.$store.state.windowWidth
    }
  }
}
</script>
<style>
[dir] .table-striped tbody tr:nth-of-type(odd) {
    background-color: #20223c;
}
[dir] .table-striped tbody tr {
    background-color: #262c49;
    color:#fff
}
thead {
    background-color: #dbdbdb;
    color: #090c2b;
}
</style>
